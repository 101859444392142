import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

export default function PrintTaskItem(props) {
  const { task, selected, onRemove, sx, disabled, onClick, ...other } = props;
  const printedPages = task.jobs.length ? task.jobs[0].printedPages : -1;
  const progress = printedPages > -1 ? printedPages / task.numberOfPages * 100 : -1;
  
  return (
      <Card
          variant="outlined"
          orientation="horizontal"
          onClick={() => {
            if (!disabled) {
              onClick?.();
            }
          }}
          {...other}
          sx={[
            {
              gap: 1.5,
              opacity: disabled ? 0.5 : 'unset',
              alignItems: 'flex-start',
              maxWidth: 700,
              cursor: progress === -1 && !disabled ? 'pointer' : 'unset'
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
      >
        { progress === -1
            ? ( selected
                ? <CheckCircleOutlinedIcon />
                : <CircleRoundedIcon /> )
            : <PrintOutlinedIcon sx={{ opacity: 0.4 }} />
        }
        <CardContent>
          <Typography fontSize="sm" fontWeight="lg">{task.name}</Typography>
          <Typography level="body-xs">{new Date(task.createdAt).toLocaleString()}</Typography>
          { progress > -1 &&
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LinearProgress
                color="neutral"
                value={progress}
                determinate
                sx={[
                  {
                    ...(progress >= 100 && {
                      color: 'var(--joy-palette-success-solidBg)',
                    }),
                  },
                ]}
            />
            <Typography fontSize="xs">{printedPages} из {task.numberOfPages}</Typography>
          </Box>
          }
        </CardContent>
        {progress >= 100 && (
            <AspectRatio
                ratio="1"
                variant="solid"
                color="success"
                sx={{
                  minWidth: 20,
                  borderRadius: '50%',
                  '--Icon-fontSize': '14px',
                }}
            >
              <div>
                <CheckRoundedIcon />
              </div>
            </AspectRatio>
        )}
        { progress < 100 && !disabled && (
            <IconButton onClick={onRemove} variant="plain" color="danger" size="sm" sx={{ mt: -1, mr: -1 }}>
              <RemoveCircleOutlineRoundedIcon />
            </IconButton>
        )}
      </Card>
  );
}