/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../components/TwoSidedLayout';
import { InstallPWA } from '../pwa/InstallPWA';
import Button from '@mui/joy/Button';

export default function HeroLeft01() {
  return (
      <TwoSidedLayout>
        <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
        >
          Infiniprint - понятное управление печатью.
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          Infiniprint позволяет сделать процесс ежедневного использования средств печати прозрачным и управляемым,
          поскольку все данные о документах проходят через центральный сервер.
        </Typography>
        <InstallPWA size="lg" endDecorator={<ArrowForward fontSize="xl" />}/>
        <a href="/driver">
          <Button
              id="setup_button"
              aria-label="Установить драйвер Windows"
              title="Установить драйвер"
              color="neutral"
              endDecorator={<ArrowForward />}
          >
            Установить драйвер принтера Windows
          </Button>
        </a>
        <Typography>
          Являетесь пользователем? <Link fontWeight="lg" href="/app">Войти</Link>
        </Typography>
      </TwoSidedLayout>
  );
}