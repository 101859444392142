import React from 'react';
import Button from '@mui/joy/Button';
import { useSupportsPWA } from './useSupportsPWA';

export const InstallPWA = ({...rest}) => {
  const {supportsPWA,promptInstall } = useSupportsPWA();
  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  
  if (supportsPWA) {
    return (
        <Button
            {...rest}
            id="setup_button"
            aria-label="Установить приложение"
            title="Установить приложение"
            onClick={onClick}
        >
          Установить приложение
        </Button>
    )
  }
  
  return null;
};