import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const usePrintSessionMutation = () => {
  return useMutation({
    mutationFn: async (newPrintSession) => {
      return await axios.post('/printsessions', newPrintSession)
    },
  });
};

export default usePrintSessionMutation;