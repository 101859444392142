import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useMeQuery = () => {
  return useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      const {data} = await axios.get('/users/me')
      return data;
    }
  });
};

export default useMeQuery;