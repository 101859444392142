import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import { useSupportsPWA } from '../pwa/useSupportsPWA';

const iosSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

const desktopSafari = () => {
  return window.safari !== undefined && !iosSafari();
}

export default function TwoSidedLayout({
  children,
  reversed,
}) {
  const { supportsPWA } = useSupportsPWA();
  const couldBeInstalled = supportsPWA || iosSafari() || desktopSafari();
  
  return (
      <Container
          sx={(theme) => ({
            position: 'relative',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: reversed ? 'column-reverse' : 'column',
            alignItems: 'center',
            py: 10,
            gap: 4,
            [theme.breakpoints.up(834)]: {
              flexDirection: 'row',
              gap: 6,
            },
            [theme.breakpoints.up(1199)]: {
              gap: 12,
            },
          })}
      >
        <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              maxWidth: '50ch',
              textAlign: 'center',
              flexShrink: 999,
              [theme.breakpoints.up(834)]: {
                minWidth: 420,
                alignItems: 'flex-start',
                textAlign: 'initial',
              },
              [`& .${typographyClasses.root}`]: {
                textWrap: 'balance',
              },
            })}
        >
          {children}
        </Box>
        <Box sx={(theme) => ({
          minWidth: 300,
          alignSelf: 'stretch',
          [theme.breakpoints.up(834)]: {
            alignSelf: 'initial',
            flexGrow: 1,
            '--AspectRatio-maxHeight': '520px',
            '--AspectRatio-minHeight': '400px',
          },
          flexBasis: '50%',
        })}>
          {!supportsPWA && couldBeInstalled && <Typography fontSize="xl" textColor="text.secondary" lineHeight="lg">Как установить?</Typography> }
          <AspectRatio
              ratio={600 / 520}
              variant="outlined"
              sx={(theme) => ({
                alignSelf: 'stretch',
                [theme.breakpoints.up(834)]: {
                  alignSelf: 'initial',
                  flexGrow: 1,
                  '--AspectRatio-maxHeight': '520px',
                  '--AspectRatio-minHeight': '400px',
                },
                flexBasis: '50%',
              })}
          >
            {(supportsPWA || !couldBeInstalled) && <img
                width="100%"
                src="https://images.unsplash.com/photo-1483791424735-e9ad0209eea2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                alt=""
            />}
            {!supportsPWA && iosSafari() &&
                <img width="100%"
                     src={process.env.PUBLIC_URL + "/pwa_ios.png"}
                     alt=""
                />
            }
            {!supportsPWA && desktopSafari() &&
                <img width="100%"
                     src={process.env.PUBLIC_URL + "/pwa_safari.png"}
                     alt=""
                />
            }
          </AspectRatio>
        </Box>
      </Container>
  );
}