import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const usePrintersQuery = () => {
  return useQuery({
    queryKey: ['printers'],
    queryFn: async () => {
      const {data} = await axios.get('/printers')
      return data;
    },
    refetchInterval: 300000,
  });
};

export default usePrintersQuery;