import {BarcodeScanner} from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"

import React from 'react';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

const Barcode = ({onCapture, title, onClick}) => {
  const handleCapture = (x) => {
    onCapture?.(x.rawValue);
  }
  return (
      <Stack alignItems="center">
        <Box sx={{height: 300}} onClick={onClick}>
          <BarcodeScanner onCapture={handleCapture}/>
        </Box>
        <Typography level="body-xs">{title}</Typography>
      </Stack>
  );
};

export default Barcode;