import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, CssVarsProvider } from '@mui/joy';
import '@fontsource/inter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import axios from 'axios';
import theme1 from './theme';
import Main from './main/Main';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://eaffaa0e28bd5dd0a7d1a58ae8b77ecb@sentry.infinitower.fun/3",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

const router = createBrowserRouter([
  {
    element: <App />,
    path: '/',
    children: [
      {
        index: true,
        element:  <img
            src="https://images.unsplash.com/photo-1483791424735-e9ad0209eea2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            alt=""
        />
      },
      {
        path: 'pwa_ios',
        element:  <img width="100%"
            src={process.env.PUBLIC_URL + "/pwa_ios.png"}
            alt=""
        />
      }
    ]
  },
  {
    path: '/app',
    element: <Main />
  }
], {
  basename: process.env.PUBLIC_URL ? process.env.PUBLIC_URL : undefined,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
enableMocking().then(() => root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <CssVarsProvider disableTransitionOnChange theme={theme1}>
          <CssBaseline/>
          <RouterProvider router={router} />
        </CssVarsProvider>
      </QueryClientProvider>
    </React.StrictMode>
));

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return undefined;
  }
  
  const worker = await import('./mocks/browser');
  
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.worker.start();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
