import { useEffect, useState } from 'react';

export const useDetectPwa = () => {
  const [pwa, setPwa] = useState(false);
  const [checking, setChecking] = useState(true);
  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setPwa(true);
    }
    setChecking(false);
  }, []);
  return {
    pwa,
    checking
  };
}