import React, { useEffect } from 'react';
import { Snackbar } from '@mui/joy';

const AlertSnackbar = ({msg: msgProp, onClose}) => {
  const [msg, setMsg] = React.useState(msgProp);
  
  useEffect(() => {
    setMsg(msgProp);
  }, [msgProp]);
  
  return (
      <Snackbar
          autoHideDuration={1000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(msg)}
          color="neutral"
          variant="solid"
          onClose={onClose}
      >
        {msg}
      </Snackbar>
  );
};

export default AlertSnackbar;