import { useEffect, useState } from 'react';

export const useSupportsPWA = () => {
  const [ supportsPWA, setSupportsPWA ] = useState(false);
  const [ promptInstall, setPromptInstall ] = useState(null);
  
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);
    
    return () => window.removeEventListener('transitionend', handler);
  }, []);
  
  return {
    supportsPWA,
    promptInstall
  };
}