import { Divider } from '@mui/joy';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import Header from './Header';

function Page({ title, children, leftItem, rightItem }) {
  return (
    <>
      <Header title={title} leftItem={leftItem} rightItem={rightItem}/>
      <Box
        component="main"
        className="MainContent"
        sx={{
          pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          gap: 1,
          overflow: 'auto',
        }}
      >
        <Box sx={{
          flex: 1,
          width: '100%',
        }}
        >
          <Box
            sx={{
              position: 'sticky',
              display: { xs: 'none', md: 'block' },
              top: {
                sm: -20,
                md: -30,
              },
              bgcolor: 'background.body',
              zIndex: 100,
            }}
          >
            <Box sx={{
              px: {
                xs: 2,
                md: 6,
              },
              pt: {
                xs: 2,
                md: 3,
              },
            }}
            >
              <Stack spacing={2} direction="row" sx={{
                alignItems: 'center',
                mt: 1,
                mb: 2,
                width: '100%',
              }}>
                {leftItem}
                <Typography
                    level="h2"
                    component="h1"
                >
                  {title}
                </Typography>
                <Box sx={{flex: 1}}/>
                {rightItem}
              </Stack>
            </Box>
            <Divider />
          </Box>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              px: {
                xs: 2,
                md: 6,
              },
              py: {
                xs: 2,
                md: 3,
              },
            }}
          >
            {children}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default Page;
