import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const sortByCreatedAt = ((a, b) => {
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  return 0;
});


const usePrintTasksQuery = () => {
  return useQuery({
    queryKey: ['printTasks'],
    queryFn: async () => {
      const {data} = await axios.get('/printtasks')
      return data?.sort?.(sortByCreatedAt);
    },
    refetchInterval: 5000,
  });
};

export default usePrintTasksQuery;