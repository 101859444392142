import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useRemoveTaskMutation = () => {
  return useMutation({
    mutationFn: async (taskId) => {
      return await axios.delete(`/printtasks/${taskId}`);
    },
  });
}

export default useRemoveTaskMutation;